import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Verify from './Verify';
import './index.less';

const { Content } = Layout;

export default () => (
    <Content>
        <div className="content">
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/verify">
                    <Verify/>
                </Route>
                <Route path="/verify/:code">
                    <Verify/>
                </Route>
                <Route path="/news">
                    <span>开发中-news</span>
                </Route>
                <Route path="/raws">
                    <span>开发中-raws</span>
                </Route>
            </Switch>
        </div>
    </Content>
)