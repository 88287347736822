import React from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import Content from './Content'
import './App.less';


const App = () => (
  <Layout>
    <Header location={useLocation()}/>
    <Content/>
    <Footer/>
  </Layout>
);

export default App;