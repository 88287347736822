import React from 'react';
import { Divider, Row, Col } from 'antd';
import { Link  } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import './index.less'

export default props => {
    const pathname = _.get(props.location, "pathname");

    return (
        <Row justify="center" align="middle" className="header">
            <Col span={12}>
                <Row align="middle" justify="space-between">
                    <img src="/img/header_logo.png" alt=""/>
                    <div className="search-box">
                        <input placeholder="搜索"/>
                    </div>
                    <div>
                        <Divider type="vertical"/>
                        <Link to="/" className={classNames({"active-link": pathname === "/"})}>首页</Link>
                        <Divider type="vertical"/>
                        <Link to="/verify" className={classNames({"active-link": pathname === "/verify"})}>防伪验真</Link>
                        <Divider type="vertical"/>
                        <a href="mailto:contact@fakeio.com" _target="blank">联系我们</a>
                    </div>
                </Row>
            </Col>
        </Row>
    );
}