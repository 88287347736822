
import React, { useState, useEffect } from 'react';
import { Input, Button, Card, Row, Col, List, Alert, Result } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import { SmileOutlined } from '@ant-design/icons';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import localization from 'moment/locale/zh-cn';
import './index.less';

moment.updateLocale('zh-cn', localization);

const handleInputChange = setInput => e => {
  let input = _.get(e, "target.value");
  if(!input) {
      setInput('');
      return
  }
  setInput(input.toUpperCase().replaceAll(' ', '').substring(0,16).match(/.{1,4}/g).join(' '));
};

const InputPanel = () => {
  const [input, setInput] = useState("");
  const history = useHistory();

  return (<div>
    <div>
      <Input placeholder="请输入16位防伪码" onChange={handleInputChange(setInput)} value={input}/>
    </div>
    <div className="button-wraper">
      <Button type="primary" disabled={input.replaceAll(' ', '').length < 16} 
        onClick={() => history.push(`/verify/${input.replaceAll(' ', '')}`)}
      >
        查询
      </Button>
    </div>
    <List itemLayout="horizontal">
      <List.Item>
        <List.Item.Meta title="第一步：获取防伪码" description="刮开防伪标涂层，获得 16 位防伪码"/>
      </List.Item>
      <List.Item>
        <List.Item.Meta title="第二步：防伪验证" description="在输入框内输入防伪标上的防伪码"/>
      </List.Item>
      <List.Item>
        <List.Item.Meta title="第三步：检查结果" description="点击查询按钮，查看验证结果"/>
      </List.Item>
    </List>
  </div>);
};

const ResultPanel = props => {
  const { code } = props; 
  const [result, setResult] = useState(null);

  useEffect(() => {
    if(result) {
      return;
    }

    axios.post("https://api.fakeio.com/verification/" + code).then(resp => {
      if(_.get(resp, 'data.count') > 1) {
        setResult(
          <Result
            status="warning"
            title="该产品被查询多次"
            subTitle={<div>
              <div>产品名称：{_.get(resp, 'data.product')}</div>
              <div>{_.get(resp, 'data.channel')}：{_.get(resp, 'data.provider')}</div>
              <div>当前为第 {_.get(resp, 'data.count')} 次查询</div>
              <div>第一次查询时间：{moment(_.get(resp, 'data.first') * 1000).format('lll')}</div>
            </div>}
          />
        );
      } else {
        setResult(
          <Result
            status="success"
            title="该商品为官方正品！"
            subTitle={<div>
              <div>产品名称：{_.get(resp, 'data.product')}</div>
              <div>{_.get(resp, 'data.channel')}：{_.get(resp, 'data.provider')}</div>
            </div>}
          />
        );
      }
    }).catch(err => {
      if(_.get(err, "response.status") === 404) {
        setResult(
          <Result
            status="404"
            title="查无此码"
            subTitle="您购买的商品非官方正品"
          />
        );
      } else {
        setResult(
          <Result
            status="500"
            title="网络错误"
            subTitle="请稍后再试"
          />
        );
      }
    });
  });

  return (<div>
    {result && result}
    {!result && <Result
      icon={<SmileOutlined />}
      title="查询中，请稍等..."
    />}
  </div>);
};

const Verify = () => {
  const { code } = useParams();

  return (
    <div className="verify">
      <Row type="flex" justify="space-around" align="top">
        <Col span={16}>
          <Card title="产品真伪查询">
            <Row justify="space-between">
              <Col span={11}>
                {!code && <InputPanel/>}
                {code && <ResultPanel code={code}/>}
              </Col>
              <Col span={11}>
                <List itemLayout="horizontal" header={<Alert message="查询结果可能为以下三种之一" type="success" />}>
                  <List.Item>
                    <List.Item.Meta title="1. 该防伪码为第一次验证" description="说明您购买的产品是正品"/>
                  </List.Item>
                  <List.Item>
                    <List.Item.Meta title="2. 该防伪码为不是第一次验证" description={<span>
                      <div>a. 请查看页面显示的第一次查询时间</div> 
                      <div>b. 确认第一次查询是否是您本人查询</div>
                      <div>c. 若第一次非是您本人查询，则产品可能不是正品</div>
                    </span>}/>
                  </List.Item>
                  <List.Item>
                    <List.Item.Meta title="3. 查无此码" description={<span>
                      <div>您购买的商品非官方正品</div>
                      <div>请注意维护自己的权益</div>
                    </span>}/>
                  </List.Item>
                </List>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
   );
 };
 
 export default Verify;