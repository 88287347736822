

import React from 'react';
import { Carousel, Row, Col, Divider } from 'antd';
import './index.less';

const DetailButton = props => {
   return (
      <img src="/img/detail_button.png" alt=""/>
   );
};

const ProductCard = props => {
   return (
      <div style={{background: `url(${props.img}) no-repeat`, height: 510, width: 285}}>
         <div style={{height: 450}}/>
         <Row justify="space-around">
            <DetailButton/>
         </Row>
      </div>
   );
};

const Home = () => {
   return (
      <div className="home">
         <Carousel autoplay>
            <img src="/img/banner_1.png" alt=""/>
            <img src="/img/banner_2.png" alt=""/>
         </Carousel>
         <Divider className="pure-divider"/>
         <Row justify="space-around">
            <img src="/img/features.png" alt=""/>
         </Row>
         <div className="product-info">
            <Divider className="pure-divider">产品信息</Divider>
            <Row justify="center">
               <Col span={16}>
                  <Row align="middle" justify="space-between">
                     <ProductCard img="/img/chiken.png" detail="chiken"/>
                     <ProductCard img="/img/beef.png" detail="beef"/>
                     <ProductCard img="/img/duck.png" detail="duck"/>
                  </Row>
               </Col>
            </Row>
         </div>
      </div>
   );
 };
 
 export default Home;