import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

function mobile() {
  return window.innerWidth < window.innerHeight;
};

if(mobile()) {
  window.location.hostname = "m.fakeio.com";
} else {
  ReactDOM.render(
      <Router>
        <App/>
      </Router>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
